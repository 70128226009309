<template>
  <b-card>
    <g-form @submit="save">
      <b-row class="my-2">
        <b-col
          md="12"
          class="border-end"
        >
          <div class="row">
            <b-col
              md="3"
            >
              <g-field
                id="code"
                v-silent-search
                type="number"
                :value.sync="invoiceCode"
                label-text="invoiceCode"
                name="code"
                @keydown.enter="
                  (v) => {
                    getInvoice();
                  }
                "
              />
            </b-col>
            <b-col
              v-if="currentBranch.enableEditingTransactionDates"
              md="3"
            >
              <g-picker
                :value.sync="selectedItem.transactionDate"
                label-text="date"
                name="date"
              />
            </b-col>
            <b-col
              v-if="!currentBranch.enableEditingTransactionDates"
              md="3"
            >
              <g-field
                :value="getDate(selectedItem.transactionDate)"
                label-text="date"
                disabled
                name="date"
              />
            </b-col>
            <b-col md="3">
              <g-field
                :value="selectedItem.transactionTime"
                label-text="transactionTime"
                name="transactionTime"
                readonly
              />
            </b-col>
            <b-col md="3">
              <g-field
                :options="lookups.stores"
                label-text="store"
                field="select"
                rules="required"
                name="StoreId"
                :value.sync="selectedItem.storeId"
                :dir="isRight ? 'rtl' : 'ltr'"
                :label="isRight ? 'arabicName' : 'englishName'"
                :disabled="isClosed || hasSource || (selectedItem.lines && selectedItem.lines.length > 0)"
              />
            </b-col>
            <b-col md="3">
              <student-autocomplete
                ref="autoComlete"
                name="student"
                rules="required"
                :value.sync="selectedItem.studentId"
                url="students/getStudentsTaxLookup"
                :display-item="{
                      id: selectedItem.studentId,
                      uid: selectedItem.studentId,
                      arabicName: selectedItem.dealerArabicName,
                    }"
                @change:action="(val) => addStudent(val) "
              />
            </b-col>
            <!-- <b-col
              v-if="!!selectedItem.sourceTransactionId"
              md="3"
            >
              <g-field
                label-text="customer"
                field="select"
                rules="required"
                :label="isRight ? 'arabicName' : 'englishName'"
                :disabled="isClosed || hasSource"
                :value.sync="selectedItem.dealerId"
                :dir="isRight ? 'rtl' : 'ltr'"
                :options="lookups.customers"
                @change="changeCustomer"
              />
            </b-col>
            <b-col
              v-if="customer.isOnTheFly"
              md="3"
            >
              <g-field
                :value.sync="selectedItem.onTheFlyDealerName"
                label-text="customerName"
                :disabled="isClosed || hasSource"
              />
            </b-col>
            <b-col
              v-if="!!selectedItem.sourceTransactionId"
              md="3"
            >
              <g-field
                label-text="agent"
                :value.sync="selectedItem.agentId"
                :dir="isRight ? 'rtl' : 'ltr'"
                :options="lookups.agents"
                field="select"
                name="agentId"
                :label="isRight ? 'arabicName' : 'englishName'"
                :disabled="isClosed || hasSource"
              />
            </b-col> -->
          </div>
        </b-col>
      </b-row>
      <b-row v-if="!selectedItem.sourceTransactionId">
        <!-- <b-col md="3">
          <b-form-group>
            <g-field
              label-text="exchangeMethods"
              :value.sync="selectedItem.exchangeDestinationType"
              :dir="isRight ? 'rtl' : 'ltr'"
              field="select"
              name="area"
              rules="required"
              :options="exchangeMethods"
              label="arabicName"
              @change="(v) => {
                methodTypeName = isRight ? v.arabicName : v.englishName;
                changedTypes(v.id)
              }"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="selectedItem.exchangeDestinationType"
          md="3"
        >
          <b-form-group>
            <g-field
              :label-text="$t(methodTypeName)"
              :value.sync="selectedItem.selectedDestinationId"
              :dir="isRight ? 'rtl' : 'ltr'"
              field="select"
              rules="required"
              name="area"
              :options="changedData"
              label="arabicName"
            />
          </b-form-group>
        </b-col> -->
        <!-- <b-col
          v-if="id"
          md="3"
        >
          <g-field
            label-text="createdBy"
            :value="selectedItem.createdUserName"
            placeholder="createdBy"
            name="createdBy"
            readonly
          />
        </b-col>
        <b-col
          v-if="id && selectedItem.updatedUserName"
          md="3"
        >
          <g-field
            label-text="updatedBy"
            :value="selectedItem.updatedUserName"
            placeholder="updatedBy"
            name="updatedBy"
            readonly
          />
        </b-col> -->
      </b-row>
      <b-row>
        <!-- notes -->
        <b-col md="12">
          <b-form-group :label="$t('notes')">
            <b-form-textarea
              id="textarea"
              v-model="selectedItem.notes"
              label-text="Notes"
              rows="3"
              max-rows="6"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col cols="12">
          <items
            :store-id="selectedItem.storeId"
            :items.sync="selectedItem.lines"
            :has-source="!!selectedItem.sourceTransactionId"
            :transaction-type="transactionType"
            @validate-balance="() => validate(invoiceValidationTypes.balance)"
          />
        </b-col>
        <b-col cols="12">
          <hr>
        </b-col>
      </b-row>
      <b-row v-show="!selectedItem.isPosted">
        <b-col
          cols="12"
          class="d-flex justify-content-end sticky-bottom"
        >
          <b-button
            v-if="id || currentBranch.isMaster"
            class="ml-1"
            variant="outline-primary"
            :disabled="selectedItem.lines.length <= 0"
            @click="print(id)"
          >
            <feather-icon
              icon="PrinterIcon"
              class="mr-50"
            />
            {{ $t('print') }}
          </b-button>
          <b-button
            v-permission="$route.meta.permission"
            class="ml-1"
            variant="outline-primary"
            data-action-type="saveAndPrint"
            :disabled="selectedItem.isComplete || (!selectedItem.sourceTransactionId && selectedItem.isPosted) || valid === false"
            @click="save('saveAndPrint')"
          >
            <feather-icon
              icon="PrinterIcon"
              class="mr-50"
            />
            {{ $t('saveAndPrint') }}
          </b-button>
          <b-button
            v-permission="$route.meta.permission"
            type="submit"
            class="ml-1"
            data-action-type="save"
            variant="relief-primary"
            :disabled="selectedItem.isComplete || (!selectedItem.sourceTransactionId && selectedItem.isPosted) || valid === false"
          >
            <feather-icon
              icon="PrinterIcon"
              class="mr-50"
            />
            {{ $t('save') }}
          </b-button>
          <b-button
            v-if="!selectedItem.sourceTransactionId"
            class="ml-1"
            variant="outline-primary"
            @click="initObj"
          >
            <feather-icon
              icon="RotateCwIcon"
              class="mr-50"
            />
            {{ $t('addNew') }}
          </b-button>
        </b-col>
      </b-row>
    </g-form>
  </b-card>
</template>

<script>
import { exchangeSalesMethods } from '@/libs/acl/Lookups';
import invoiceMixin from '@/mixin/invoiceMixinStores';
import reportMixin from '@/mixin/reportMixin';
import StudentAutocomplete from '@/components/StudentAutoComplete.vue';
import items from './components/items.vue';

export default {
  components: {
    items,
    StudentAutocomplete
  },
  mixins: [invoiceMixin, reportMixin],
  props: ['id'],
  data() {
    return {
      lookups: {},
      selectedItem: {
        lines: []
      },
      exchangeDest: [],
      changedData: [],
      exchangeDestList: [],
      methodTypeName: '',
      exchangeMethods: exchangeSalesMethods,
      transactionType: 'exchangeOrder',
      payemntType: 'Safes',
      paymentMethod: {
        value: null,
        id: null,
        arabicName: '',
        englishName: '',
      },
      customer: {},
      invoiceCode: null,
      payment: {
        transactionId: 0,
        lineSerial: 0,
        value: 0,
        paymentMethod: 'safe',
        safeId: 0,
        otherPaymentMethodId: 0,
        referenceNumber: 'string',
      },
      items: [],
      errors: [],
      sourceId: null,
      isValid: true,
    };
  },
  computed: {
    valid() {
      this.selectedItem.lines.forEach((res) => {
        if (this.selectedItem.sourceTransactionId && res.originalQuantity && (res.originalQuantity - res.consumedQuantity || 0) < res.quantity) {
        res.isValid = false;
      } else if (this.selectedItem.sourceTransactionId && res.originalQuantity && (res.originalQuantity - res.consumedQuantity || 0) >= res.quantity) {
        res.isValid = true
      }
    })
    for (let i = 0; i < this.selectedItem.lines.length; i++) {
      const element = this.selectedItem.lines[i];
      if (element.isValid === false) {
        this.doneAlert({ text: this.$t('originalQuantityIsLessThanQty'), type: 'error' });
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.isValid = false;
        break;
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.isValid = true
      }
    }
    return this.isValid;
    }
  },
  watch: {
    'selectedItem.exchangeDestinationType'(newVal) {
      switch (newVal) {
        case 'customers':
          this.changedData = this.lookups.customers;
          break;
        case 'agents':
          this.changedData = this.lookups.agents;
          break;
        case 'exchangeDestinations':
          this.changedData = this.exchangeDestList;
          break;
        default:
          break;
      }
    },
  },
  beforeMount() {
    this.updateViewModel();
  },
  mounted() {
    // this.getExchangeDestinations();
    this.sourceId = this.selectedItem.sourceTransactionId;
    this.selectedItem.transactionDate = this.getDate();
    this.selectedItem.transactionTime = this.getTime()
    this.selectedItem.transactionType = this.transactionType;
    this.get({
      url: `Aggregates/salesViewModel?userId=${this.profile.userId}&isClosed=false&transactionType=sales&dealerType=`,
    }).then((data) => {
      this.lookups = data;
    }).then(() => {
      const id = this.id || this.selectedItem.sourceTransactionId;
      if (id > 0) {
        let transactionType = this.$route.query.sourceTransactionType;
        if (this.id) transactionType = this.transactionType;
        this.get({ url: `ItemTransactions/${transactionType}/${id}` }).then(
          (data) => {
            this.selectedItem.studentId = data.dealerId;
            this.selectedItem = data;
            this.selectedItem.lines.forEach((item) => {
              item.units = [
                {
                  unitId: item.unitId,
                  unitArabicName: item.unitArabicName,
                  unitEnglishName: item.unitEnglishName,
                  price: item.salesPrice,
                  minSalesPrice: item.minSalesPrice,
                  maxSalesPrice: item.maxSalesPrice,
                  barcode: item.mainUnitBarcode,
                  isMain: true,
                },
              ];
              item.costPrice = item.costPrice * item.unitConversionRate || 0;
            });
            this.selectedItem.exchangeDestinationType = data.exchangeDestinationType;
            this.changedTypes(data.exchangeDestinationType);
            const exhangeDestObj = this.exchangeMethods.find(
              (val) => val.id === this.selectedItem.exchangeDestinationType
            ) || {
              arabicName: ''
            }

            this.methodTypeName = this.isRight ? exhangeDestObj.arabicName : exhangeDestObj.englishName;
            if (this.sourceId > 0) this.prepareItemfromSource();
          }
        );
      }
    })
  },
  methods: {
    addStudent(val) {
      this.selectedItem.dealerId = val.id;
      this.students.push(val)
    },
    changedTypes(newVal) {
      switch (newVal) {
        case 'customers':
          this.changedData = this.lookups.customers;
          break;
        case 'agents':
          this.changedData = this.lookups.agents;
          break;
        case 'exchangeDestinations':
          this.changedData = this.exchangeDestList;
          break;
        default:
          break;
      }
    },
    getExchangeDestinations() {
      this.get({ url: 'ExchangeDestinations' }).then((data) => {
        this.exchangeDestList = data;
      });
    },
    getInvoice() {
      if (this.invoiceCode) {
         this.get({ url: `itemTransactions/sales?code=${this.invoiceCode}` }).then(
        (res) => {
          if (res.data.length > 0) {
             this.selectedItem.lines = res.data[0].lines;
             this.selectedItem.lines.forEach((item) => {
               item.originalQuantity = item.quantity;
            item.consumedQuantity = item.consumedQuantity === null ? 0 : item.consumedQuantity;
            item.units = [
              {
                unitId: item.unitId,
                unitArabicName: item.unitArabicName,
                unitEnglishName: item.unitEnglishName,
                price: item.salesPrice,
                minSalesPrice: item.minSalesPrice,
                maxSalesPrice: item.maxSalesPrice,
                barcode: item.mainUnitBarcode,
                isMain: true,
              },
            ];
          });
          this.prepareItemfromSource();
          } else {
            this.selectedItem.lines = []
          }
          }
      )
      } else {
            this.selectedItem.lines = []
          }
    },
    prepareItemfromSource() {
      this.selectedItem.id = 0;
      this.selectedItem.transactionType = this.transactionType;
      if (this.sourceId) {
        this.selectedItem.isPosted = false;
        this.selectedItem.postedVoucherId = null;
        this.selectedItem.voucherCode = null;
        this.selectedItem.costCenters = [];
      }
      this.selectedItem.lines = this.selectedItem.lines.map((item) => {
        return {
          ...item,
          consumedQuantity: item.consumedQuantity === null ? 0 : item.consumedQuantity,
          originalQuantity: item.quantity,
          quantity: item.quantity - (item.consumedQuantity || 0),
        };
      });
      this.selectedItem.uuid = '00000000-0000-0000-0000-000000000000';
      this.selectedItem.transactionTime = this.time;
      this.selectedItem.transactionDate = this.today;
      this.selectedItem.code = null;
      this.selectedItem.referenceNumber = null;
      this.selectedItem.sourceTransactionId = this.sourceId;
      this.selectedItem.total = 0;
      this.selectedItem.discountPercentage = 0;
      this.selectedItem.discountValue = 0;
      this.selectedItem.netBeforeTaxes = 0;
      this.selectedItem.totalTaxes = 0;
      this.selectedItem.net = 0;
      this.selectedItem.taxes = [];
      this.selectedItem.payments = [];
    },
    changeCustomer(v) {
      if (!this.isLineBaseDiscount) {
        this.selectedItem.discountPercentage = v.applyDiscount && !this.isLineBaseDiscount ? v.discount : 0;
      }
    },
    validate(validationType) {
      let url = '';
      let type = '';
      if (validationType === this.invoiceValidationTypes.balance) {
        url = 'ItemTransactions/validate/item-balance';
        type = this.currentBranch.negativeItemBalancePolicy;
      } else {
        url = 'ItemTransactions/validate/sales-price';
        type = this.currentBranch.minSalesPricePolicy;
      }
      if (type === 'none') return;
      this.errors = this.errors.filter((item) => item.errorType !== type);
      this.create({
        url,
        data: this.selectedItem,
      }).catch(({ data }) => {
        this.doneAlert({ text: data.detail, type: type });
      });
    },
    initObj() {
       this.getSelecteItemObj();
       this.selectedItem.transactionType = this.transactionType;
    },
    validateBalance() {
      this.create({
        url: 'ItemTransactions/validate/item-balance',
        data: this.selectedItem,
      })
        .then(() => {})
        .catch(({ data }) => {
          this.errors.push({
            text: data.detail,
            type: 'error',
            errorType: 'balance',
          });
        });
    },
    beforeSaveValidationDestination() {
      if (!this.selectedItem.sourceTransactionId) {
        if (this.selectedItem.exchangeDestinationType && !this.selectedItem.selectedDestinationId) {
          // alert here
          this.doneAlert({ text: this.$t('youShouldSelectSelectedDestination'), type: 'error' });
          return false;
        }
        if (!this.selectedItem.exchangeDestinationType && !this.selectedItem.selectedDestinationId) {
          // alert here
          this.doneAlert({ text: this.$t('youShouldSelectSelectedDestination'), type: 'error' });
          return false;
        }
      }
      return true;
    },
    // beforeSaveValidation() {
    //   this.valid = true;
    //   this.validTwo = false;
    //   this.selectedItem.lines.forEach((res) => {
    //     if ((res.originalQuantity - res.consumedQuantity || 0) < res.quantity) {
    //     // alert here
    //     this.doneAlert({ text: this.$t('originalQuantityIsLessThanQty'), type: 'error' });
    //     this.valid = false;
    //   } else {
    //     this.validTwo = true;
    //   }
    //   })
    // },
    save(type) {
     // if (!this.beforeSaveValidationDestination()) return;
      if (this.selectedItem.lines.find(a => a.quantity <= 0)) {
        this.doneAlert({
          text: this.$t('youAreNotAllowedToAddQtyEqualZero'),
          type: 'error'
          });
        return;
      }
      // if (this.id) {
      //   this.beforeSaveValidation();
      // }
      // if (!this.valid && this.validTwo) return;
      if (this.errors.length > 0) {
        this.doneAlert(this.errors[0]);
        return;
      }
      if (this.selectedItem.lines.length === 0) {
        this.doneAlert({ text: this.$t('youMustChooseOneItemAtLeast'), type: 'error' });
        return
      }
      // if paid amount more than needed
      if (this.remaindered < 0) {
        this.doneAlert({
          type: 'error',
          text: this.$t('paidMoreThanRequired'),
        });
        return;
      }

      // fill data
      this.selectedItem.dealerType = 'student';
      // then save
      (this.id > 0
        ? this.update({
            url: `ItemTransactions/${this.selectedItem.transactionType}`,
            id: this.id,
            data: this.getModel(),
          }).then(() => {
          this.doneAlert({
            title: this.$t('savedSuccessfully'),
            type: 'success',
          });
          const id = this.id;
          if (type === 'saveAndPrint') this.print(id);
          if (this.selectedItem.sourceTransactionId > 0) this.$router.push({ name: 'invoice-list' });
          else {
            this.$router.push({ name: 'orders' });
               this.getSelecteItemObj();
               this.selectedItem.transactionType = this.transactionType;
          }
        })
        : this.create({
            url: `ItemTransactions/${this.selectedItem.transactionType}`,
            data: this.getModel(),
          }).then((data) => {
          this.doneAlert({
            title: this.$t('savedSuccessfully'),
            type: 'success',
          });
          const id = data.id
          if (type === 'saveAndPrint') this.print(id);
          if (this.selectedItem.sourceTransactionId > 0) this.$router.push({ name: 'invoice-list' });
          else {
               this.getSelecteItemObj();
               this.selectedItem.transactionType = this.transactionType;
               this.$router.push({ name: 'orders' });
          }
        })
      )
    },
      print(id) {
      const printedItem = {
        id: id
      }
       this.printReport('ExchangeOrder-ar', printedItem);
    },
  },
};
</script>
